import { useRouteError } from 'react-router-dom';
import './style/error.scss';

export default function ErrorPage() {
	const error = useRouteError();

	return (
		<div className='error'>
			{error.status ? (
				<>
					<h1>
						<i>
							<span>{error.status} : </span>
							{error?.statusText || error?.message}
						</i>
					</h1>
					{`No Resource for ${error.error.message
						.split(' ')
						.slice(4)}`}
				</>
			) : (
				<h1>Something went wrong</h1>
			)}
		</div>
	);
}
