import React, { useState, useEffect } from 'react';
import AuthCard from './authCard';
import { getMsgs } from '../func/getMsgs';

export default function SignUp() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [prevFormData, setPrevFormData] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alertMsg, setAlertMsg] = useState({
		status: false,
		error: false,
		success: false,
		msg: '',
		detailMsg: '',
	});

	useEffect(() => {
		if (loading) {
			handleSignUp();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleSignUp = async () => {
		try {
			const response = await fetch(
				'https://blog.kas-websolutions.dev/.netlify/functions/signUp',
				//'http://localhost:8888/.netlify/functions/signUp',
				{
					method: 'POST',
					body: JSON.stringify({ email, password }),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			setPrevFormData({ email, password });

			setEmail('');
			setPassword('');

			const data = await response.json();

			if (response.status === 200) {
				setAlertMsg((prevAlertMsg) => ({
					...prevAlertMsg,
					status: true,
					error: false,
					success: true,
					msg: data.message,
					detailMsg: getMsgs(response),
				}));
				// You can redirect the user to a login page after successful registration
			} else if (response.status === 409) {
				setAlertMsg((prevAlertMsg) => ({
					...prevAlertMsg,
					status: true,
					error: true,
					success: false,
					msg: data.message,
					detailMsg: getMsgs(response),
				}));
			} else {
				setAlertMsg((prevAlertMsg) => ({
					...prevAlertMsg,
					status: true,
					error: true,
					success: false,
					msg: data.message,
					detailMsg: getMsgs(response),
				}));
			}

			setLoading(false);
		} catch (error) {
			setAlertMsg((prevAlertMsg) => ({
				...prevAlertMsg,
				status: true,
				error: true,
				success: false,
				msg: error.message,
				detailMsg: '',
			}));

			setLoading(false);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
	};

	return (
		<div className='container my-5'>
			<div className='row p-2 gap-2'>
				<div className='col-12 col-md-4 my-5 '>
					<AuthCard
						handleAuth={handleSubmit} // Changed to handleSubmit
						email={email}
						password={password}
						setEmail={setEmail}
						setPassword={setPassword}
						cardType='Sign Up'
						alertMsg={alertMsg}
						showPassword={showPassword}
						setShowPassword={setShowPassword}
					/>
				</div>
				{loading && <p>Loading...</p>}
				{alertMsg.status && alertMsg.detailMsg.length > 0 && (
					<div className='d-none d-md-block  col-7 my-5 p-2 bg-body-tertiary'>
						{alertMsg.detailMsg.slice(0, 9).map((item, index) => (
							<div key={index}>
								<i className='text-muted '>{item}</i>
								<br />
							</div>
						))}
					</div>
				)}
			</div>

			{prevFormData !== null && (
				<div className='row p-2'>
					<div className='col'>
						<p className='text-info'>
							For test purposes - here is what you entered.
						</p>

						<p>{`Email: ${prevFormData.email}  – Password: ${prevFormData.password}`}</p>
					</div>
				</div>
			)}
		</div>
	);
}
