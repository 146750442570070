import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './layout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './errorPage';
import SignIn from './auth/signin';
import SignUp from './auth/signup';
import Home from './home';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <ErrorPage />,
		children: [
			{
				errorElement: <ErrorPage />,
				children: [
					{ path: '/', element: <Home /> },
					{ path: '/signin', element: <SignIn /> },
					{ path: 'signup', element: <SignUp /> },
				],
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
