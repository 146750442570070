export default function AuthCard({
	handleAuth,
	email,
	password,
	setEmail,
	showPassword,
	setPassword,
	setShowPassword,
	cardType,
	alertMsg,
}) {
	return (
		<>
			<div className='card mb-3'>
				<div className='card-body'>
					<p>
						Password constraints are currently absent, allowing
						acceptance of any value{' '}
					</p>
					<p>
						Emails must be unique. Note: For testing purposes,
						please use dummy email and password.
					</p>
					<p>Example: Email - test@test.com, Password - Test@123</p>
				</div>
			</div>
			<div className='card'>
				<div className='card-header'>
					<h2 className='text-center'>{cardType}</h2>
				</div>

				<div className='card-body'>
					<form onSubmit={handleAuth}>
						<div className='input-group mb-1'>
							<input
								type='email'
								placeholder='Email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className='form-control mb-1'
							/>
						</div>
						<div className='input-group mb-1'>
							<input
								required
								type={showPassword ? 'text' : 'password'}
								placeholder='Password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className=' form-control mb-1 '
							/>
							<div
								className='input-group-append '
								onClick={() => setShowPassword(!showPassword)}
							>
								<div
									className='input-group-text btn btn-primary '
									style={{
										borderBottomLeftRadius: 0,
										borderTopLeftRadius: 0,
									}}
								>
									{showPassword ? 'Hide' : 'Show'}
								</div>
							</div>
						</div>
						<button
							type='submit'
							className='btn btn-primary'
							
						>
							{cardType}
						</button>
					</form>
				</div>
				{alertMsg.status && (
					<div className='card-footer'>
						<p
							className={`${
								alertMsg.error ? 'text-danger' : ''
							} ${alertMsg.success ? 'text-success' : ''}`}
						>
							{alertMsg.msg.severity
								? alertMsg.msg.severity
								: alertMsg.msg}
						</p>
					</div>
				)}
			</div>
		</>
	);
}
