import React from 'react';
import Navigation from './navigation';
import { Outlet } from 'react-router-dom';

import './style/layout.scss';

export default function Layout() {
	return (
		<div className='layout-wraper'>
			<Navigation />
			<Outlet />
		</div>
	);
}
