import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useLocation } from 'react-router-dom';

export default function Navigation() {
	const location = useLocation();
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setExpanded(false);
	}, [location]);
	return (
		<Navbar
			expand={'lg'}
			expanded={expanded}
			onToggle={() => setExpanded(!expanded)}
			className='bg-body-tertiary fixed-top'
		>
			<div className='container'>
				<Navbar.Brand href='/'>Logo</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ms-auto nav-lists '>
						<Nav.Link
							as={NavLink}
							to='/signin'
							activeclassname='active'
						>
							Sign in
						</Nav.Link>
					</Nav>
					<Nav className='nav-lists '>
						<Nav.Link
							as={NavLink}
							to='/signup'
							activeclassname='active'
						>
							Sign up
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</div>
		</Navbar>
	);
}
