import React, { useState, useEffect } from 'react';
import AuthCard from './authCard';
import { getMsgs } from '../func/getMsgs';

export default function SignIn() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [alertMsg, setAlertMsg] = useState({
		status: false,
		error: false,
		success: false,
		msg: '',
		detailMsg: '',
	});

	useEffect(() => {
		if (loading) {
			handleSignIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const handleSignIn = async () => {
		try {
			const response = await fetch(
				'https://blog.kas-websolutions.dev/.netlify/functions/signin',
				//'http://localhost:8888/.netlify/functions/signin',
				{
					method: 'POST',
					body: JSON.stringify({ email, password }),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			setEmail('');
			setPassword('');
			const data = await response.json();

			if (response.status === 200) {
				setAlertMsg((prevAlertMsg) => ({
					...prevAlertMsg,
					status: true,
					error: false,
					success: true,
					msg: data.message,
					detailMsg: getMsgs(response),
				}));
			} else {
				setAlertMsg((prevAlertMsg) => ({
					...prevAlertMsg,
					status: true,
					error: true,
					success: false,
					msg: data.message,
					detailMsg: getMsgs(response),
				}));
			}

			setLoading(false); // Request completed, set loading to false
		} catch (error) {
			setAlertMsg((prevAlertMsg) => ({
				...prevAlertMsg,
				status: true,
				error: true,
				success: false,
				msg: error.message,
				detailMsg: '',
			}));

			setLoading(false); // Request completed (with error), set loading to false
			console.error('An error occurred:', error);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true); // Start loading and trigger handleSignIn via useEffect
	};

	return (
		<div className='container my-5'>
			<div className='row gap-2'>
				<div className='col-12 col-md-4 my-5'>
					<AuthCard
						handleAuth={handleSubmit} // Changed to handleSubmit
						email={email}
						password={password}
						setEmail={setEmail}
						showPassword={showPassword}
						setPassword={setPassword}
						setShowPassword={setShowPassword}
						cardType='Sign in'
						alertMsg={alertMsg}
					/>
				</div>

				{loading && <p>Loading...</p>}
				{alertMsg.status && (
					<div className='d-none d-md-block col-7 my-5 p-2 my-5 bg-body-tertiary'>
						{alertMsg.detailMsg.length > 0 &&
							alertMsg.detailMsg
								.slice(0, 9)
								.map((item, index) => (
									<div key={index}>
										<i className='text-muted '>{item}</i>
										<br />
									</div>
								))}
					</div>
				)}
			</div>

			{alertMsg.success && (
				<div>
					<i className='text-success'>
						Redirect to allowed resources
					</i>
				</div>
			)}
		</div>
	);
}
