export const CODE_SNIPPET = [
	{
		id: 1,
		title: 'db.js',
		snippet: `const { Pool } = require('pg');
require('dotenv').config();

class Database {
	constructor() {
		this.pool = new Pool({
			connectionString: process.env.DB_URL,
			ssl: {
				rejectUnauthorized: false,
			},
		});
	}

	async query(text, values) {
		try {
			const result = await this.pool.query(text, values);
			
			return result;
		} catch (error) {
			
			throw error;
		}
	}
}

const db = new Database();

module.exports = db;
`,
	},

	{
		id: 2,
		title: 'signin.js',
		snippet: `// netlify/functions/signIn.js

const db = require('./db');
const bcrypt = require('bcryptjs');

exports.handler = async (event) => {
	try {
		const { email, password } = JSON.parse(event.body);

		// Check if the user exists
		const checkQuery = 'SELECT * FROM users WHERE email = $1';
		const result = await db.query(checkQuery, [email]);

		if (result.rowCount === 0) {
			
			return {
				statusCode: 404,
				body: JSON.stringify({ message: 'User not found' }),
			};
		}

		const user = result.rows[0];

		// Compare hashed password
		const passwordMatch = await bcrypt.compare(password, user.password);
		
		if (passwordMatch) {
			return {
				statusCode: 200,
				body: JSON.stringify({ message: 'Authentication successful' }),
			};
		} else {
			return {
				statusCode: 401,
				body: JSON.stringify({ message: 'Authentication failed' }),
				headers: {
					'Access-Control-Allow-Origin':
						'https://blog.kas-websolutions.dev/signin',
					'Access-Control-Allow-Methods': 'POST',
					'Access-Control-Allow-Headers': 'Content-Type',
				},
			};
		}
	} catch (error) {
		return {
			statusCode: 500,
			body: JSON.stringify({ message: 'An error occurred' }),
		};
	}
};
`,
	},
	{
		id: 3,
		title: 'signUp.js',
		snippet: `// netlify/functions/signup.js

const db = require('./db');
const bcrypt = require('bcryptjs');

exports.handler = async (event) => {
	try {
		const { email, password } = JSON.parse(event.body);
		// Check if the email is already registered
		
		const checkQuery = 'SELECT * FROM users WHERE email = $1';
		const result = await db.query(checkQuery, [email]);

		if (result.rowCount > 0) {
			return {
				statusCode: 409,
				body: JSON.stringify({ message: 'Email already registered' }),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': 'https://blog.kas-websolutions.dev/signup',
					'Access-Control-Allow-Methods': 'POST',
					'Access-Control-Allow-Headers': 'Content-Type', 
				},
			};
		} else {
			try {
				const saltRounds = 10; // Number of salt rounds
				const hashedPassword = await bcrypt.hash(password, saltRounds);

				// Store the hashed password and other user data in the database
				const insertQuery =
					'INSERT INTO users (email, password) VALUES ($1, $2)';
				await db.query(insertQuery, [email, hashedPassword]);
				
				return {
					statusCode: 200,
					body: JSON.stringify({
						message: 'User registered successfully',
					}),
				};
			} catch (error) {
			
				throw error;
			}
		}

		
	} catch (error) {
	
		return {
			statusCode: 500,
			body: JSON.stringify({ message: 'An error occured' }),
		};
	}
};
`,
	},
];
