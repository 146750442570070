import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CODE_SNIPPET } from './CODE_SNIPPET';
import { Link } from 'react-router-dom';

export default function Home() {
	return (
		<div className='container-fluid bg-body-tertiary '>
			<div className='row flex-column  p-2 d-flex align-items-center justify-content-center'>
				<div className='col col-md-6 text-adjustment bg-white'>
					<div className='card my-5'>
						<div className='card-body '>
							<ul>
								<li className='my-2'>
									<Link to='/signup'>
										Try to signup and signin{' '}
									</Link>
								</li>
								<li>
									<a
										href='https://github.com/surafel9/serverless-authentication'
										target='_blank'
										rel='noopener noreferrer'
									>
										Check out the code on GitHub
									</a>
								</li>
							</ul>
						</div>
					</div>
					<h2 className='mt-5 mb-4'>
						Netlify & ElephantSQL - Part 1
					</h2>
					<h2>Summary</h2>
					<p>
						The synergy of Netlify and ElephantSQL sets the stage
						for crafting a functional user authentication system,
						seamlessly integrating hosting, serverless functions,
						and database management. While this demonstration is a
						promising starting point, it's essential to acknowledge
						that it's just a fraction of the complexities inherent
						in a real-world authentication practice.{' '}
					</p>
					<p>
						For a comprehensive authentication system, there's a
						gamut of indispensable features yet to be implemented.
						Aspects such as password reset mechanisms, email
						confirmations, OAuth integration, and multi-factor
						authentication are crucial for ensuring security and
						user convenience.{' '}
					</p>
					<p>
						Moreover, robust input validation, both on the frontend
						and server-side, is essential to fend off potential
						vulnerabilities. Incorporating JSON Web Tokens (JWT) for
						secure token-based authentication is another pivotal
						layer. Even in the React part, the current use of index
						for listing items is a simplified approach; ideally,
						each item should possess a unique identifier for optimal
						efficiency and maintainability.
					</p>
					<p>
						{' '}
						While this demo offers a glimpse into the possibilities,
						a truly robust authentication system demands meticulous
						attention to detail and a commitment to implement
						numerous additional features. The path ahead encompasses
						a myriad of challenges and rewards, enabling you to
						delve deeper into full-stack development and create
						solutions that cater to intricate real-world
						requirements.
					</p>

					<p className='mb-4'>
						In the world of web development, experimenting with
						full-stack features can be a daunting task, especially
						for those who are just starting out or seeking a
						budget-friendly approach. Fortunately, there are free
						resources available that allow you to explore and learn
						the ropes without breaking the bank. In this article,
						we'll walk through the process of creating a demo that
						utilizes Netlify for hosting and ElephantSQL for
						database management. Our demo will implement user
						authentication via Netlify serverless functions,
						providing a foundational understanding of full-stack
						development concepts.
					</p>

					<h2>Creating a Markdown Editing Experience</h2>
					<p>
						My goal is to build a live Markdown editing experience
						that offers synchronization and team cooperation. While
						these advanced features are part of the roadmap, let's
						start by focusing on user authentication, a key
						component of any collaborative platform.
					</p>
					<h3>Step 1: Setting Up the Project</h3>
					<p>
						To begin, create a new React project using your
						preferred setup. Once you have your project structure in
						place, consider organizing your components and services
						for better maintainability.
					</p>
					<h3>Step 2: Integrating Netlify for Hosting</h3>
					<p>
						Netlify offers hassle-free hosting for static websites
						and serverless functions. Deploying your app to Netlify
						is as simple as connecting your GitHub repository and
						specifying the build settings. For this demonstration,
						we'll use Netlify serverless functions for user
						authentication.
						<i>Consider using netlify cli</i>
					</p>
					<h3>Connecting to ElephantSQL</h3>
					<p>
						ElephantSQL offers PostgreSQL as a service, enabling you
						to manage databases effortlessly. Create a PostgreSQL
						database and configure your credentials. You'll use
						these credentials to connect your serverless functions
						to the database.
					</p>
					<h3>Step 4: User Authentication with Netlify Functions</h3>
					<p>
						Netlify's serverless functions allow you to handle
						backend logic without the need for dedicated servers.
						Implementing user authentication is a crucial step, and
						Netlify makes it surprisingly straightforward. The
						following are functions that we need for our simple user
						sign-up and sign-in:
					</p>

					{CODE_SNIPPET.map((code) => (
						<div key={code.id} className='my-5'>
							<i>{code.title}</i>
							<SyntaxHighlighter
								language='javascript'
								style={github}
							>
								{code.snippet}
							</SyntaxHighlighter>
						</div>
					))}
					<h3>
						Lessons Learned While Building and Deploying Web
						Applications
					</h3>

					<ul>
						<li>
							<strong>Function Naming:</strong> While it might
							seem trivial, the consistency in function naming is
							crucial. I encountered an issue where I initially
							named a function as "signUp" and later changed it to
							"signup." This seemingly minor change caused
							unexpected errors in my code. It's important to
							ensure that any changes you make in function names
							are reflected throughout your codebase and
							deployment environment.
						</li>
						<li>
							<strong>Clear Caching During Deployment:</strong>{' '}
							There were instances where changes I made to my code
							were not immediately reflected after deploying to
							the server. This led to confusion and unexpected
							errors. To avoid this, make sure to clear the
							deployment cache and redeploy your application after
							making changes. This ensures that your updates are
							properly reflected and any cached content doesn't
							interfere with the new deployment.
						</li>
						<li>
							<strong>Handling HTML Errors:</strong> During
							deployment, you might encounter HTML errors instead
							of the expected JSON responses. These errors can
							include messages like "Unexpected token ...not valid
							JSON," or "SyntaxError: Unexpected end of JSON
							input." These errors are usually caused by issues in
							your serverless functions or incorrect
							configurations. It's important to carefully review
							your code and configuration files to identify and
							rectify the problems.
						</li>
						<li>
							<strong>Utilize Console Logs for Debugging:</strong>{' '}
							When you're facing issues with your serverless
							functions, debugging can become challenging. A
							valuable tool for debugging is the strategic use of{' '}
							<code>console.log("")</code> statements within your
							serverless functions. These logs provide real-time
							insights into the execution flow and potential
							errors. By checking the logs on the Netlify
							dashboard or other relevant platforms, you can
							quickly identify issues and troubleshoot them
							effectively.
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
